import React from 'react'

const ValueSection = () => {
    return (
        <section className='value-section gap'>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-4 col-md-6" data-aos="fade-up">
                        <h4 className="big-num">1</h4>
                        <h3>Great Selection
                        </h3>
                        <p>
                        From marble and granite to quartz countertops available in New Jersey, we have the right surface solutions to fit projects of any size.
                        </p>
                    </div>
                    <div className="col-lg-4 col-md-6" data-aos="fade-up">
                        <h4 className="big-num">2</h4>
                        <h3>Trusted Sources
                        </h3>
                        <p>
                        Our countertop showroom in New Jersey carries the finest and most exquisite surfaces from trusted brands worldwide.

                        </p>
                    </div>
                    <div className="col-lg-4 col-md-6" data-aos="fade-up">
                        <h4 className="big-num">3</h4>
                        <h3>
                        Customer First

                        </h3>
                        <p>
                        Putting customers first, Astra Surfaces in New Jersey offers top-quality countertops, unmatched service and dedication to your satisfaction.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ValueSection