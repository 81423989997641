import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Products from "./components/Products";
import Footer from "./components/Footer";
import Blogs from "./components/Blog";
import WhichIsBest from "./components/GraniteCountertopsVsQuartzCountertopsWhichIsBest";
import TopKitchenCountertopTrends from "./components/Top-6-Kitchen-Countertop-Trends-2024";
import Contact from "./components/Contact";
import ProductInside from "./components/Product-Inside";
import ProductDetails from "./components/Product-Details";
import ThankYou from "./components/ThankYou";
import NotFound from "./components/NotFound";
import Faq from "./components/Faq";
import Warranty from "./components/Warranty";
import Catalogs from "./components/Catalogs";
import DesignGallery from "./components/DesignGallery";
import Resources from "./components/Resources";
import PerfectKitchenCountertops from "./components/7-Types-of-Kitchen-Countertops";
import CountertopLondon from "./components/countertop-store-in-lindon-nj";
function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/products/*" element={<Products />} />
        <Route exact path="/products/:category" element={<ProductInside />} />
        {/* <Route exact path="/variant-porcelain" element={<VariantPorcelain />} />
        <Route exact path="/quartzite" element={<QuartziteTile />} />
        <Route exact path="/marble" element={<Marble />} />
        <Route exact path="/granite" element={<Granite />} />
        <Route exact path="/soapstone" element={<SoapStone />} /> */}
        <Route exact path="/product-details/:id" element={<ProductDetails />} />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/choosing-the-perfect-kitchen-countertop-a-comprehensive-guide" element={< PerfectKitchenCountertops />} />

        <Route exact path="/countertop-store-in-linden-new-jersey" element={< CountertopLondon />} />


        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/manuals" element={<Catalogs />} />
        <Route exact path="/design-gallery" element={<DesignGallery />} />
        <Route exact path="/warranty-registration" element={<Warranty />} />
        <Route exact path="/thank-you" element={<ThankYou />} />
        <Route exact path="/resources" element={<Resources />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
