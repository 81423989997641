import React from 'react'
import { Link } from 'react-router-dom'

const BlogSection = () => {
    return (
        <section className='blog-section gap'>
            <div className="bg-color">
                <div className="container">
                    <div className="row gx-3">

                        <div className="col-lg-6" data-aos="fade-right">
                            <Link to={"/Granite-Countertops-Vs-Quartz-Countertops:-Which-is-best?"} className='link'>
                                <div className="card">
                                    <img src="assests/images/Macchia-antica-1-scaled.jpg" alt="" className='card-image' />
                                    <div className="card-body">
                                        <h3>Granite Countertops Vs. Quartz Countertops: Which is best?</h3>
                                        <p>
                                            Want to know the difference between granite and quartz countertops? Read this blog to understand granite and quartz countertops, their pros and cons, and understand which one fits your space the best!
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-6" data-aos="fade-left">
                            <Link to={"/choosing-the-perfect-kitchen-countertop-a-comprehensive-guide"} className='link'>
                                <div className="card">
                                    <img src="assests/images/cristallo-rose-scaled.jpeg" alt="" className='card-image' />
                                    <div className="card-body">
                                        <h3>Choosing the Perfect Kitchen Countertop: A Comprehensive Guide</h3>

                                        <p>
                                            Choosing the right surface can transform the look and functionality of your kitchen.
                                            Different surfaces have unique compositions and production methods that impact their
                                            design and durability.
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogSection