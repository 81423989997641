import React from "react";

const AimSection = () => {
  return (
    <section className="aim-section gap">
      <div className="row gx-5">
        <div className="col-lg-6 mb-lg-0 mb-3">
          <div className="image-wrapper">
            <img src="assests/countertoplondon/Image01.jpg" alt="" />
          </div>
        </div>
        <div className="col-lg-4 my-auto">
          <div
            className="text-wrapper ps-lg-0 ps-md-4 ps-3"
            data-aos="fade-left"
          >
            <h3>We believe in collaborations</h3>
            <p className="mt-lg-4 mt-3">
              At Astra Surfaces, we believe crafting interiors involves
              understanding and turning the vision into a wonderful reality. Our
              experienced team works closely with designers, architects,
              contractors, and homeowners, ensuring that every countertop
              contributes to the interior aesthetic seamlessly.
            </p>
            <p>
              If you are an architect, interior designer or homeowner looking
              for a powerful inspiration for your next commercial or residential
              project, a reliable collaboration with us is all you need!
            </p>
          </div>
        </div>
        <div className="col-2"></div>
      </div>
      <div className="row gx-lg-5 mt-lg-5 mt-3">
        <div className="col-xl-2 col-lg-1"></div>
        <div className="col-xl-4 col-lg-5 ps-md-5 ps-4 d-flex flex-column justify-content-between">
          <div className="text-wrapper my-auto" data-aos="fade-up">
            <h2>Why Astra Surfaces For Countertops in NJ?
            </h2>
            <p className="mt-md-4 mt-3">
            Astra Surfaces in Linden, NJ, is your go-to for high-quality countertops. With a vast showroom and slab yard, choose your perfect granite, marble, quartzite, or quartz countertop on-site. Enjoy exceptional service, quick delivery, and affordable prices. Visit us and discover why we have been New Jersey’s trusted choice for countertops since 2010.

            </p>

            <p className="mt-md-4 mt-3">
            Let's transform your space together! Contact us today for a free, no-obligation estimate and experience our exceptional service and quality.


            </p>
          </div>
          <div className="row mt-5 mb-lg-0 mb-3">
            <div className="col-4 d-flex flex-column justify-content-center border-end border-2">
              <h6 className="percentage-font">100%</h6>
              <small>Customer service</small>
            </div>
            <div className="col-4 d-flex flex-column justify-content-center border-end border-2">
              <h6 className="percentage-font">100%</h6>
              <small>Fair pricing</small>
            </div>
            <div className="col-4 d-flex flex-column justify-content-center">
              <h6 className="percentage-font">100%</h6>
              <small>After sales-support</small>
            </div>
          </div>
        </div>
        <div className="col-xxl-4 col-lg-5">
          <div className="image-wrapper">
            <img src="assests/countertoplondon/image02.jpg" alt="" />
          </div>
        </div>
        <div className="col-xxl-2"></div>
      </div>

      <section className="section-8">
        <div className="row justify-content-center">
          <div
            className="col-lg-8 col-md-10 d-flex flex-column align-items-center"
            data-aos="fade-left"
          >
            <img src="assests/countertoplondon/image03.jpg" alt="" />
            <p>
            With our quartz and natural stone countertops in New Jersey, the ‘new’ never fade away; they are a statement, enduring and beautiful, for years to come.

            </p>
          </div>
        </div>
      </section>
    </section>
  );
};

export default AimSection;
