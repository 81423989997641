import React, { useEffect, useRef, useState } from "react";
import { PiSlidersBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import AOS from "aos";
import Loader from "./Loader";
import { Helmet } from "react-helmet";
// import { useProductContext } from "../context/CategoryContext";
import { CiSearch } from "react-icons/ci";
import ScrollTopButton from "./ScrollTop";
import { useProductContext } from "../context/CategoryContext";

const ProductsCategory = () => {
  const [screenLoading, setScreenLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const productRefs = useRef({});

  const { selectedCategoryProducts, productsCategory, fetchProductsByCategory, selectedCategory, updateSelectedCategory } = useProductContext();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [collections, setCollections] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');



  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (!loading) {
      const savedProductId = sessionStorage.getItem('selectedProductId');
      if (savedProductId && productRefs.current[savedProductId]) {
        productRefs.current[savedProductId].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }
  }, [loading]);

  useEffect(() => {
    if (selectedCategory) {
      fetchProductsByCategory(selectedCategory.category_url);
      setCollections(selectedCategory.collections || []);
      setSubcategories(selectedCategory.subcategories || []);
    }
  }, [selectedCategory])

  // useEffect(() => {
  //   updateFilters();
  // }, [selectedCategoryProducts])

  useEffect(() => {
    let filteredProducts = selectedCategoryProducts;

    if (selectedCollection) {
      filteredProducts = filteredProducts.filter(product => product.collection === selectedCollection);
    }

    if (selectedSubcategory) {
      filteredProducts = filteredProducts.filter(product => product.subcategory_name === selectedSubcategory);
    }


    setFilteredProducts(filteredProducts);
    // console.log(selectedCategory);
    // console.log(productsCategory);
  }, [selectedCategoryProducts, selectedCollection, selectedSubcategory]);

  const handleCollectionClick = (collection) => {
    setSelectedCollection(collection);
    setSelectedSubcategory(''); // Clear selected subcategory
  };

  const handleSubcategoryClick = (subcategory) => {
    setSelectedSubcategory(subcategory);
    setSelectedCollection(''); // Clear selected collection
  };

  const handleCategoryClick = async (category) => {
    await fetchProductsByCategory(category.category_url);
    updateSelectedCategory(category)
    setSelectedCollection('');
    setSelectedSubcategory('');
  }


  const onProductClick = (productId) => {
    sessionStorage.setItem('selectedProductId', productId);
  }

  const categoryImages = {
    "Variant Quartz": "/CALACATTA DIOR 001.webp",
    "Variant Porcelain™": "/Macchia-antica-1-scaled.webp",
    "Natural Stone": "/Ebano 001.webp",
  };
  const categoryDetails = {
    "Variant Quartz": {
      para1: "Redefine Luxury and Durability",
      para2: "Transform your living spaces with Variant Quartz surfaces that seamlessly blend beauty with durability, adding a touch of sophistication to every corner of your home or office.",
    },
    "Variant Porcelain™": {
      para1: "Porcelain For Inspired Spaces",
      para2: "Resistant to scratches, stains, and heat, Variant Porcelain is a stunning and practical addition to your space.",
    },
    "Natural Stone": {
      para1: "Nature’s Canvas Of Style and Durability",
      para2: "Our exclusive collection of quartzite, granite, marble, soapstone, and sodalite showcases elegance, durability, and versatility in each slab design.",
    },
  };

  const categoryContents = {
    "Variant Quartz": {
      item1: "Limited Lifetime Warranty",
      item2: "No Maintenance",
      item3: "Scratch-Resistance",
      item4: "Heat Resistance",
      item5: "Stain-Resistance",
      item6: "Durable",
    },
    "Variant Porcelain™": {
      item1: "Mold Proof",
      item2: "Water and Moisture Proof",
      item3: "Stain Proof",
      item4: "Scratch and Impact Resistance",
      item5: "UV Resistance",
      item6: "Heat and Frost Resistance",
    },
    "Natural Stone": {
      item1: "Heat Resistance",
      item2: "",
      item3: "Easy Maintenance",
      item4: "",
      item5: "Stain Resistance",
      item6: ""
    },
  };
  return (
    <>
      {selectedCategory && selectedCategory.category && ( // Ensure selectedCategory and category are defined
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Countertops | products | quartz | marble | granite | quartzite
          </title>
          <link rel="canonical" href={`https://www.astrasurfaces.com/products/${selectedCategory.category.replace(/\s+/g,"-")}`} />
          <meta
            name="description"
            content="Explore the vast range of surface products Astra Surfaces offers in New Jersey. Browse through the page to learn our latest quartz, marble, granite, and quartzite countertops in different designs, colors, textures, and more. Get your interior inspiration right here!"
          />
        </Helmet>
      )}
      <>
        {screenLoading ? (
          <Loader />
        ) : (
          <>
            <div className="product-banner">
              <div className="row gx-0">
                <div className="col-lg-7">
                  <img src={categoryImages[selectedCategory.category]} alt="" />
                </div>
                <div className="col-lg-5">
                  <div className="product-banner-text">
                    <h1>{selectedCategory.category}
                      {selectedCategory.category === "Variant Quartz" && <sup className="registeredTrademark" style={{ "left": "0" }}>®</sup>}
                    </h1>
                    {categoryDetails[selectedCategory.category]?.para1 && (
                      <p>{categoryDetails[selectedCategory.category].para1}</p>
                    )}
                    <hr />
                    {categoryDetails[selectedCategory.category]?.para2 && (
                      <p>{categoryDetails[selectedCategory.category].para2}</p>
                    )}
                  </div>
                </div>
              </div>

            </div>

            <section className="product-inside-container gap">
              <div className="container">
                <div className="row justify-content-end">
                  <div className="col-lg-9">
                    <div className="row product-inside-text-container justify-content-center">
                      <div className="col-md-4 border-right">
                        {categoryContents[selectedCategory.category]?.item1 && (
                          <h6>{categoryContents[selectedCategory.category].item1}</h6>
                        )}
                        {categoryContents[selectedCategory.category]?.item2 && (
                          <h6>{categoryContents[selectedCategory.category].item2}</h6>
                        )}
                      </div>
                      <div className="col-md-4 border-right">
                        {categoryContents[selectedCategory.category]?.item3 && (
                          <h6>{categoryContents[selectedCategory.category].item3}</h6>
                        )}
                        {categoryContents[selectedCategory.category]?.item4 && (
                          <h6>{categoryContents[selectedCategory.category].item4}</h6>
                        )}
                      </div>
                      <div className="col-md-4">
                        {categoryContents[selectedCategory.category]?.item5 && (
                          <h6>{categoryContents[selectedCategory.category].item5}</h6>
                        )}
                        {categoryContents[selectedCategory.category]?.item6 && (
                          <h6>{categoryContents[selectedCategory.category].item6}</h6>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gap">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="filter-container">
                        <div className="d-flex mb-4">
                          <PiSlidersBold className="icon" />
                          <h6>FILTER BY</h6>
                        </div>

                      </div>
                      <hr />
                      <div className="input-group search mb-5">
                        <input type="text"
                          className="form-control search__input"
                          placeholder="Search"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <div className="input-group-append">
                          <button className="search__btn" type="button">
                            <CiSearch className="fa fa-search search__icon" />
                          </button>
                        </div>
                      </div>

                      <div className="accordion" id="accordionPanelsStayOpenExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                              Category
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                            <div className="accordion-body">
                              <ul className='py-2'>
                                {productsCategory.map((category) => (
                                  <li key={category.id} onClick={() => handleCategoryClick(category)}>
                                    {category.category}
                                    {category.category === "Variant Quartz" && <sup className="registeredTrademark" style={{ "left": "0" }}>®</sup>}
                                  </li>
                                ))}
                              </ul>

                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {console.log("subcategories")}
                      {console.log(subcategories)} */}
                      {subcategories && subcategories.length > 0 ?
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                          <div className="accordion-item mt-3">
                            <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
                                Sub Category
                              </button>
                            </h2>
                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
                              <div className="accordion-body">
                                <ul className='py-2'>
                                  {subcategories.map(subcategory => (
                                    <li key={subcategory.id} onClick={() => handleSubcategoryClick(subcategory.subcategory)}>
                                      {subcategory.subcategory}
                                    </li>
                                  ))}
                                </ul>

                              </div>
                            </div>
                          </div>
                        </div>
                        : <></>}
                      {collections && collections.length > 0 ?
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                          <div className="accordion-item mt-3">
                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="true" aria-controls="panelsStayOpen-collapseThree">
                                Collection
                              </button>
                            </h2>
                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThree">
                              <div className="accordion-body">
                                <ul className='py-2'>
                                  {[...new Set(collections.map(collection => collection.toUpperCase()))].map((collection, index) => (
                                    <li onClick={() => handleCollectionClick(collection)} key={collection}>
                                      {collection}
                                    </li>
                                  ))}
                                </ul>

                              </div>
                            </div>
                          </div>
                        </div>
                        : <></>}


                    </div>
                    <div className="col-lg-9 mt-lg-0 mt-5">
                      <div className="row">


                        {filteredProducts.filter(product =>
                          searchQuery === '' || product.product_name.toLowerCase().includes(searchQuery.toLowerCase())
                        ).map((product) => (
                          <div
                            className="col-lg-4 col-md-6 d-flex flex-column align-items-center mb-md-4 mb-3"
                            key={product.id}
                            ref={(el) => (productRefs.current[product.id] = el)}
                            onClick={() => onProductClick(product.id)}
                          >
                            <Link
                              to={`/product-details/${product.id}`}
                              className="link"
                            >
                              <div className="image-wrapper mx-auto">
                                {product.images.map((image) => (
                                  <img
                                    key={image.id}
                                    src={image.image_url}
                                    alt={image.product_name}
                                    loading="lazy"
                                  />
                                ))}
                              </div>
                              <h5>{product.product_name}</h5>
                            </Link>
                          </div>
                        ))}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <ScrollTopButton />
          </>
        )}
      </>
    </>
  );
};

export default ProductsCategory;
