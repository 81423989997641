import React, { useEffect, useState } from 'react'
import AOS from "aos";
import { Helmet } from "react-helmet";
import { useScrollToTop } from './UserScrollTop';
import Banner from './Banner';
import { Link } from 'react-router-dom';
import Cta_Section from './Cta-section';

const Resources = () => {
    const [screenLoading, setScreenLoading] = useState(false);

    useEffect(() => {
        AOS.init({ duration: 1500 });
        AOS.refresh();
        setScreenLoading(false);
        setTimeout(() => {
            setScreenLoading(false);
        }, 3000);
    }, []);

    useScrollToTop();
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Astra Surfaces | Resources</title>
                <link rel="canonical" href="https://www.astrasurfaces.com/resources" />
                <meta name="description" content="Discover our comprehensive resource page featuring detailed catalogs, FAQs, and warranty information for our premium countertops in New Jersey. All the answers you need, all in one place." />
            </Helmet>
            <>
                <Banner name="Resources" url="assests/images/CALACATTA 3CM 001.webp" />
                <section className='resources-section gap'>
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-3 ">
                            <div className='wrapper'>
                                <h3>Manuals</h3>
                                <img src="assests/images/certificate.png" alt="" className='icon' />
                                <p>
                                    Find installation guides, product specifications, and more.
                                </p>
                                <Link to={"/manuals"}><button>Learn More</button></Link>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-lg-0 mt-4">
                            <div className='wrapper'>
                                <h3>FAQs</h3>
                                <img src="assests/images/faq.png" alt="" className='icon' />
                                <p>
                                    Learn more about Astra Surfaces.
                                </p>
                                <Link to={"/faq"}><button>Learn More</button></Link>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-lg-0 mt-4">
                            <div className='wrapper'>
                                <h3>Warranty</h3>
                                <img src="assests/images/guarantee.png" alt="" className='icon' />
                                <p>
                                    Register your surface(s) warranty.
                                </p>
                                <Link to={"/warranty-registration"}><button>Learn More</button></Link>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="gap">
                    <Cta_Section />
                </div>
            </>
        </>
    )
}

export default Resources