import React, { useEffect, useState } from 'react'
import Loader from './Loader';
import { Helmet } from 'react-helmet';
import { useScrollToTop } from './UserScrollTop';

const Faq = () => {
    const [screenLoading, setScreenLoading] = useState(false);

    useEffect(() => {
        setScreenLoading(false);
        setTimeout(() => {
            setScreenLoading(false);
        }, 3000);
    }, []);

    useScrollToTop()
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Astra Surfaces | FAQ</title>
                <link rel="canonical" href="https://www.astrasurfaces.com/faq" />
                <meta name="description" content="Discover answers to common questions about our high-quality countertops. Learn about materials, maintenance, installation, and more on our comprehensive FAQ page." />
            </Helmet>
            <>
                {screenLoading ? (
                    <Loader />
                ) :
                    <section id='faq' className='gap-1'>
                        <div className="section-2">
                            <div className="row">
                                <div className="col-lg-8 col-md-10 mx-auto">
                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Why Astra?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        We pride ourselves on procuring the highest-quality surfaces from around the world at the best prices because of our strong relationships with the world’s top stone suppliers. Equally important is our knowledgeable and well-organized team, who streamline the buying process to ensure highly satisfied customers and quick deliveries.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    What types of surfaces does Astra carry?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        We carry a large collection of quartz, porcelain, quartzite, granite, marble, dolomite, soapstone, and sodalite slabs.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    How will you know which stone surfaces are right for you when you arrive at Astra?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        We have a team of experts ready to help you find the perfect surfaces for your needs. They will guide you through our collection and provide all the information you need to make the best selection.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                    You have picked the perfect surfaces for your project at Astra. Now what?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        Once you have selected your slabs, we will hold them for you for two weeks. During this time, you will finalize your details through your fabricator, and we will deliver your slabs directly to your fabricator.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    Can I get a quartz countertop for my kitchen?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        Our showroom showcases various quartz countertops in numerous designs, patterns, and colors. Visit us, and our team will guide you in choosing the best countertop for your space.
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </>
        </>
    )
}

export default Faq