import React from "react";
import { Link } from "react-router-dom";

const CatalogueSection = () => {
  return (
    <section className="catalogue-section gap ">
      <div className="row justify-content-end ">
        <div className="col-lg-4 my-lg-auto mb-3">
          <div className="text-wrapper" data-aos="fade-right">
            <h3>
              Download Our <br />
              Latest Catalogue
            </h3>
            <p>
              Join us as we craft interiors where functionality meets artistry,
              style meets sustainability, and everyday living meets innovation.
            </p>
            <p>
              Download our catalog and explore the endless possibilities of
              kitchen countertops for your space.
            </p>
            <Link to={"/contact-us"}>
              <button>Request A Callback</button>
            </Link>
          </div>
        </div>
        <div className="col-lg-1"></div>
        <div className="col-lg-4" data-aos="fade-left">
          <div>
            <img
              src="assests/countertoplondon/book.png"
              alt="catalog-img"
              className="catalog-img"
              style={{}}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CatalogueSection;
