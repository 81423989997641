import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import { Helmet } from 'react-helmet';
import Loader from './Loader';
import Banner from './Banner';
import { useScrollToTop } from './UserScrollTop';

const Blogs = () => {
    const [screenLoading, setScreenLoading] = useState(false);

    useEffect(() => {
        AOS.init({ duration: 1500 });
        AOS.refresh();
        setScreenLoading(false);
        setTimeout(() => {
            setScreenLoading(false);
        }, 3000);
    }, []);

    useScrollToTop()
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Astra Surfaces - Blogs</title>
                <link rel="canonical" href="https://www.astrasurfaces.com/Blogs" />
                <meta name="description" content="Uncover trends, tips, and insights on our blog. Stay informed and inspired with our latest posts on countertops. Increase your knowledge today." />
            </Helmet>
            <>
                {screenLoading ? (
                    <Loader />
                ) :
                    <>
                        <Banner name="Blogs" url="assests/images/CALACATTA ARABASCATO 001.webp" />
                        {/* <div className='banner-section'>
                            <img src="assests/images/CALACATTA ARABASCATO 001.webp" alt="banner-img" />
                            <div className='banner-text'>
                                <h1>Blogs</h1>
                            </div>
                        </div> */}
                        <section className='blog-pag gap'>
                            <div className="container">
                                <div className="row gx-0 d-flex justify-content-center mb-5">
                                    <div className="col-lg-10 mt-lg-5" data-aos="fade-right">
                                        <div className="row blog gx-0">
                                            <div className="col-lg-4">
                                                <div className="image-wrapper">
                                                    <img src="assests/kitchenblog.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-8 my-auto blog-content border">
                                                <h2>Choosing the Perfect Kitchen Countertop: A Comprehensive Guide</h2>
                                                <hr />
                                                <p>
                                                    Choosing the right surface can transform the look and functionality of your kitchen.
                                                    Different surfaces have unique compositions and production methods that impact their
                                                    design and durability.
                                                </p>
                                                <Link to={"/choosing-the-perfect-kitchen-countertop-a-comprehensive-guide"}><button>Read More..</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                }
            </>
        </>
    )
}

export default Blogs