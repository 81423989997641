import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import { MdFileDownload } from "react-icons/md";
import { useScrollToTop } from './UserScrollTop';

const Warranty = () => {
    const [screenLoading, setScreenLoading] = useState(false);
    const [captchaCode, setCaptchaCode] = useState('');
    const [userInput, setUserInput] = useState('');

    const generateCaptcha = () => {
        const chr1 = Math.ceil(Math.random() * 10) + '';
        const chr2 = Math.ceil(Math.random() * 10) + '';
        const chr3 = Math.ceil(Math.random() * 10) + '';

        const str = new Array(4)
            .join()
            .replace(/(.|$)/g, function () {
                return (
                    (Math.random() * 36) | 0
                ).toString(36)[Math.random() < 0.5 ? 'toString' : 'toUpperCase']();
            });

        const newCaptchaCode = str + chr1 + ' ' + chr2 + ' ' + chr3;
        setCaptchaCode(newCaptchaCode);
    };

    const removeSpaces = (string) => {
        return string.split(' ').join('');
    };

    useEffect(() => {
        setScreenLoading(false);
        setTimeout(() => {
            setScreenLoading(false);
        }, 3000);
        generateCaptcha();
    }, []);

    // const [startDate, setStartDate] = useState(new Date());
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        from_company: "Astra",
        first_name: '',
        last_name: '',
        mobile: '',
        apt_no: '',
        street: '',
        city: '',
        zip_code: '',
        state: '',
        country: '',
        email: '',
        // reenterEmail: '',
        product_warranty_type: '',
        product_installation_date: null,
        product_total_sqft: '',
        // installationType: '',
        // installationArea: '',
        product_thickness: '',
        product_stove_type: '',
        product_color_name: '',
        product_lot_no: '',
        retailer_type: '',
        retailerCompanyName: '',
        retailer_street: '',
        retailer_city: '',
        retailer_zip_code: '',
        retailer_state: '',
        fabricator_company: '',
        fabricator_street: '',
        fabricator_city: '',
        fabricator_zip_code: '',
        fabricator_state: '',
        botcheck: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };
    const handleDateChange = (date) => {
        setFormData((prevData) => ({ ...prevData, product_installation_date: date }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formattedCaptcha = removeSpaces(captchaCode);
        const formattedUserInput = removeSpaces(userInput);
        const { botcheck, ...warrantyFormData } = formData;
        if (formattedCaptcha === formattedUserInput) {
            if (botcheck == '') {
                try {
                    const response = await axios.post(
                        'https://astrasurfaces.cretxglobal.com/warranty/',
                        warrantyFormData,
                        {
                            auth: {
                                username: `${process.env.REACT_APP_API_USERNAME}`,
                                password: `${process.env.REACT_APP_API_PASSWORD}`,
                            },
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }
                    );
                    setFormData({
                        first_name: '',
                        last_name: '',
                        mobile: '',
                        apt_no: '',
                        street: '',
                        city: '',
                        zip_code: '',
                        state: '',
                        country: '',
                        email: '',
                        // reenterEmail: '',
                        product_warranty_type: '',
                        product_installation_date: null,
                        product_total_sqft: '',
                        // installationType: '',
                        // installationArea: '',
                        product_thickness: '',
                        product_stove_type: '',
                        product_color_name: '',
                        product_lot_no: '',
                        retailer_type: '',
                        retailerCompanyName: '',
                        retailer_street: '',
                        retailer_city: '',
                        retailer_zip_code: '',
                        retailer_state: '',
                        fabricator_company: '',
                        fabricator_street: '',
                        fabricator_city: '',
                        fabricator_zip_code: '',
                        fabricator_state: '',
                    });
                    navigate('/thank-you');
                    // console.log(formData)
                    // console.log(warrantyFormData)
                } catch (error) {
                    console.error('Error submitting form:', error.message);
                }
            }
        }
        else {
            // Captcha validation failed
            alert('Please enter a proper Captcha.');
        }
        generateCaptcha();
    };

    useScrollToTop()
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Astra Surfaces | Warranty</title>
                <link rel="canonical" href="https://www.astrasurfaces.com/warranty-registration" />
                <meta name="description" content="Register your countertop with our easy warranty registration. Enjoy peace of mind with our comprehensive coverage and dedicated customer support. Sign up today!" />
            </Helmet>
            <>
                {screenLoading ? (
                    <Loader />
                ) :
                    <section className='gap-1'>
                        <div className="container">
                            <div className="row mt-5 justify-content-center">
                                <div className="col-lg-6 mb-5">
                                    <div className="catalogue_card">
                                        <div className="catalogue_img">
                                            <img src="assests/warranty/Variant residental warranty.png" alt="" />
                                        </div>
                                        <div className='catalogue_name'>
                                            <p className='my-auto'>
                                                Residential Warranty
                                            </p>
                                            <a href="assests/warranty/VS_Residential-Warranty.pdf" target="_blank"><MdFileDownload className='download-icon' /></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-5">
                                    <div className="catalogue_card">
                                        <div className="catalogue_img">
                                            <img src="assests/warranty/Variant commerciol warranty.png" alt="" />
                                        </div>
                                        <div className='catalogue_name'>
                                            <p className='my-auto'>
                                                Commercial Warranty
                                            </p>
                                            <a href="assests/warranty/VS_Commercial_Warranty_Manual.pdf" target="_blank"><MdFileDownload className='download-icon' /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="warranty-form gap">
                            <div className="container">
                                <h1>WARRANTY REGISTRATION FORM</h1>
                                <form onSubmit={handleSubmit}>
                                    <h3>PERSONAL INFORMATION</h3>
                                    <hr />
                                    <div className="row">
                                        <div className="col-12">
                                            <input name="botcheck" className='visually-hidden' tabIndex="-1" autoComplete="off" onChange={handleChange} value={formData.botcheck} />
                                        </div>
                                        <div className="col-12">
                                            <input type="text" className="visually-hidden" value={formData.from_company} placeholder="" name='from_company' onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" placeholder=" " required name='first_name' value={formData.first_name} onChange={handleChange} />
                                                <label>*First Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" placeholder=" " required name='last_name' value={formData.last_name} onChange={handleChange} />
                                                <label>*Last Name</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input type="tel" placeholder='' pattern="[0-9]{10}" required name='mobile' value={formData.mobile} onChange={handleChange} />
                                                <label>*Phone Number</label>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" placeholder=" " name='apt_no' value={formData.apt_no} onChange={handleChange} />
                                                <label>Apt.No</label>
                                            </div>
                                        </div> */}
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input type="text" placeholder=" " required name='street' value={formData.street} onChange={handleChange} />
                                                <label>*Street</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" placeholder=" " required name='city' value={formData.city} onChange={handleChange} />
                                                <label>*City</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" placeholder=" " required name='zip_code' value={formData.zip_code} onChange={handleChange} />
                                                <label>*Zipcode</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" placeholder=" " required name='state' value={formData.state} onChange={handleChange} />
                                                <label>*State</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" placeholder=" " required name='country' value={formData.country} onChange={handleChange} />
                                                <label>*Country</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="email" placeholder=" " required name='email' value={formData.email} onChange={handleChange} />
                                                <label>*Email</label>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                <div className="form-group">
                                    <input type="email" placeholder=" " required name='reenterEmail' value={formData.reenterEmail} onChange={handleChange} />
                                    <label>Re-enter Email</label>
                                </div>
                            </div> */}
                                    </div>
                                    <h3>PRODUCT INFORMATION</h3>
                                    <hr />

                                    <p>*Warranty Type</p>
                                    <div className='d-flex'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="product_warranty_type" id="Residential"
                                                value="Residential"
                                                checked={formData.product_warranty_type === 'Residential'}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label className="form-check-label" htmlFor="Residential">
                                                Residential
                                            </label>
                                        </div>
                                        <div className="form-check ms-5">
                                            <input className="form-check-input" type="radio" name="product_warranty_type" id="Commercial"
                                                value="Commercial"
                                                checked={formData.product_warranty_type === 'Commercial'}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="Commercial">
                                                Commercial
                                            </label>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-lg-6">
                                            <p className='top-margin'>*Installation Date</p>
                                            <div className=''>
                                                <DatePicker selected={formData.product_installation_date} onChange={handleDateChange} className="custom-datepicker" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <p className='top-margin'>*Total Square Footage Installed</p>
                                            <div className='mb-0 form-group'>
                                                <input type="text" placeholder='' required name='product_total_sqft' value={formData.product_total_sqft} onChange={handleChange} />
                                                <label>Total Sq. Ft.</label>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6">
                                <p className='top-margin'>*Installation Type</p>
                                <div className='d-flex'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="installationType" id="remodel"
                                            value='remodel'
                                            checked={formData.installationType === 'remodel'}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="remodel">
                                            Remodel
                                        </label>
                                    </div>
                                    <div className="form-check ms-5">
                                        <input className="form-check-input" type="radio" name="installationType" id="new-construction"
                                            value='new-construction'
                                            checked={formData.installationType === 'new-construction'}
                                            onChange={handleChange} />
                                        <label className="form-check-label" htmlFor="new-construction">
                                            New Construction
                                        </label>
                                    </div>
                                    <div className="form-check ms-5">
                                        <input className="form-check-input" type="radio" name="installationType" id="other"
                                            value='other'
                                            checked={formData.installationType === 'other'}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="other">
                                            Other
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                                        {/* <div className="col-lg-6">
                                <p className='top-margin'>*Installation Area</p>
                                <div className='d-flex'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="installationArea" id="counter-top"
                                            value="counter-top"
                                            checked={formData.installationArea === 'counter-top'}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="counter-top">
                                            Counter top
                                        </label>
                                    </div>
                                    <div className="form-check ms-5">
                                        <input className="form-check-input" type="radio" name="installationArea" id="walls"
                                            value="walls"
                                            checked={formData.installationArea === 'walls'}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="walls">
                                            Walls
                                        </label>
                                    </div>
                                    <div className="form-check ms-5">
                                        <input className="form-check-input" type="radio" name="installationArea" id="floor"
                                            value="floor"
                                            checked={formData.installationArea === 'floor'}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="floor">
                                            Floor
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                                        <div className="col-lg-6">
                                            <p className='top-margin'>*Thickness</p>
                                            <div className='d-flex'>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="product_thickness" id="2-cm"
                                                        value="2-cm"
                                                        checked={formData.product_thickness === '2-cm'}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <label className="form-check-label" htmlFor="2-cm">
                                                        2 cm
                                                    </label>
                                                </div>
                                                <div className="form-check ms-5">
                                                    <input className="form-check-input" type="radio" name="product_thickness" id="3-cm"
                                                        value="3-cm"
                                                        checked={formData.product_thickness === '3-cm'}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="3-cm">
                                                        3 cm
                                                    </label>
                                                </div>
                                                <div className="form-check ms-5">
                                                    <input className="form-check-input" type="radio" name="product_thickness" id="other-2"
                                                        value="other-2"
                                                        checked={formData.product_thickness === 'other-2'}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="other-2">
                                                        Other
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <p className='top-margin'>*Type Of Stove</p>
                                            <div className='d-flex'>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="product_stove_type" id="slide-in"
                                                        value="slide-in"
                                                        checked={formData.product_stove_type === 'slide-in'}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <label className="form-check-label" htmlFor="slide-in">
                                                        Slide in
                                                    </label>
                                                </div>
                                                <div className="form-check ms-5">
                                                    <input className="form-check-input" type="radio" name="product_stove_type" id="in-counter"
                                                        value="in-counter"
                                                        checked={formData.product_stove_type === 'in-counter'}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="in-counter">
                                                        In counter
                                                    </label>
                                                </div>
                                                <div className="form-check ms-5">
                                                    <input className="form-check-input" type="radio" name="product_stove_type" id="not-applicable"
                                                        value="not-applicable"
                                                        checked={formData.product_stove_type === 'not-applicable'}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="not-applicable">
                                                        Not applicable
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <p className='top-margin'>*Color Name</p>
                                            <div className='form-group'>
                                                <input type="text" placeholder='' required name='product_color_name' value={formData.product_color_name} onChange={handleChange} />
                                                <label>Enter Colors</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <p className='top-margin'>Lot Number</p>
                                            <div className='form-group'>
                                                <input type="text" placeholder='' name='product_lot_no' value={formData.product_lot_no} onChange={handleChange} />
                                                <label>Lot#</label>
                                            </div>
                                        </div>
                                    </div>

                                    <h3>RETAILER INFORMATION</h3>
                                    <hr />

                                    <div className="row">
                                        <div className="">
                                            <p>*Purchase From</p>
                                            <div className='d-md-flex d-block'>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="retailer_type" id="dealer"
                                                        value="dealer"
                                                        checked={formData.retailer_type === 'dealer'}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <label className="form-check-label" htmlFor="dealer">
                                                        Dealer
                                                    </label>
                                                </div>
                                                <div className="form-check ms-md-5 ms-0">
                                                    <input className="form-check-input" type="radio" name="retailer_type" id="builder"
                                                        value="builder"
                                                        checked={formData.retailer_type === 'builder'}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="builder">
                                                        Builder
                                                    </label>
                                                </div>
                                                <div className="form-check ms-md-5 ms-0">
                                                    <input className="form-check-input" type="radio" name="retailer_type" id="fabricator"
                                                        value="fabricator"
                                                        checked={formData.retailer_type === 'fabricator'}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="fabricator">
                                                        Fabricator
                                                    </label>
                                                </div>
                                                <div className="form-check ms-md-5 ms-0">
                                                    <input className="form-check-input" type="radio" name="retailer_type" id="general-contractor"
                                                        value="general-contractor"
                                                        checked={formData.retailer_type === 'general-contractor'}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="general-contractor">
                                                        General Contractor
                                                    </label>
                                                </div>
                                                <div className="form-check ms-md-5 ms-0">
                                                    <input className="form-check-input" type="radio" name="retailer_type" id="other-3"
                                                        value="other-3"
                                                        checked={formData.retailer_type === 'other-3'}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="other-3">
                                                        Other
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <p className='top-margin'>*Company Name</p>
                                            <div className='form-group'>
                                                <input type="text" placeholder='' required name='retailerCompanyName' value={formData.retailerCompanyName} onChange={handleChange} />
                                                <label>Retailer company name</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <p className='top-margin'>*Street</p>
                                            <div className='form-group'>
                                                <input type="text" placeholder='' required name='retailer_street' value={formData.retailer_street} onChange={handleChange} />
                                                <label>Street</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>*City</p>
                                            <div className='form-group'>
                                                <input type="text" placeholder='' required name='retailer_city' value={formData.retailer_city} onChange={handleChange} />
                                                <label>City</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>*ZipCode</p>
                                            <div className='form-group'>
                                                <input type="text" placeholder='' required name='retailer_zip_code' value={formData.retailer_zip_code} onChange={handleChange} />
                                                <label>Zip code</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>*State</p>
                                            <div className='form-group'>
                                                <input type="text" placeholder='' required name='retailer_state' value={formData.retailer_state} onChange={handleChange} />
                                                <label>Select State</label>
                                            </div>
                                        </div>
                                    </div>

                                    <h3>FABRICATOR/INSTALLER INFORMATION</h3>
                                    <hr />
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <p>*Company Name</p>
                                            <div className='form-group'>
                                                <input type="text" placeholder='' required name='fabricator_company' value={formData.fabricator_company} onChange={handleChange} />
                                                <label>Company name</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>*Street</p>
                                            <div className='form-group'>
                                                <input type="text" placeholder='' required name='fabricator_street' value={formData.fabricator_street} onChange={handleChange} />
                                                <label>Street</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>*City</p>
                                            <div className='form-group'>
                                                <input type="text" placeholder='' required name='fabricator_city' value={formData.fabricator_city} onChange={handleChange} />
                                                <label>City</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>*ZipCode</p>
                                            <div className='form-group'>
                                                <input type="text" placeholder='' required name='fabricator_zip_code' value={formData.fabricator_zip_code} onChange={handleChange} />
                                                <label>Zip code</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>*State</p>
                                            <div className='form-group'>
                                                <input type="text" placeholder='' required name='fabricator_state' value={formData.fabricator_state} onChange={handleChange} />
                                                <label>Select State</label>
                                            </div>
                                        </div>
                                        <div className="form-group mt-3">
                                            <input
                                                type="text"
                                                id="txtCaptcha"
                                                style={{
                                                    textAlign: 'center',
                                                    border: 'none',
                                                    fontWeight: 'bold',
                                                    fontSize: '20px',
                                                    fontFamily: 'Modern',
                                                }}
                                                readOnly
                                                value={captchaCode}
                                            />
                                            <input
                                                type="button"
                                                id="btnrefresh"
                                                value="Refresh"
                                                onClick={generateCaptcha}

                                            />
                                        </div>
                                        <div className="form-group mt-3">
                                            <input
                                                type="text"
                                                placeholder="Enter Captcha"
                                                className="form-control"
                                                id="txtCompare"
                                                value={userInput}
                                                onChange={(e) => setUserInput(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type='submit'>Submit</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </section>
                }
            </>
        </>
    )
}

export default Warranty