import React, { useEffect, useState } from "react";
import AOS from "aos";
import { Helmet } from "react-helmet";
import Banner from "./Banner";
import Loader from "./Loader";
import Fancybox from "./FancyBox";
import { Swiper, SwiperSlide } from "swiper/react";
import { MdFileDownload } from "react-icons/md";
import { Link } from "react-router-dom";
import { useProductContext } from "../context/CategoryContext";
import { useScrollToTop } from "./UserScrollTop";
// Import Swiper styles
import "swiper/css";
import ValueSection from "./ValueSection";
import AimSection from "./AimSection";
import CatalogueSection from "./CatalogueSection";
import BlogSection from "./BlogSection";
const CountertopLondon = () => {
  const [screenLoading, setScreenLoading] = useState(false);
  const { productsCategory, updateSelectedCategory, fetchProductsByCategory } =
    useProductContext();


  useEffect(() => {
    AOS.init({ duration: 1500 });
    AOS.refresh();
    setScreenLoading(false);
    setTimeout(() => {
      setScreenLoading(false);
    }, 3000);
  }, []);
  const handleClick = async (category) => {
    await fetchProductsByCategory(category.category_url);
    updateSelectedCategory(category);
  };
  useScrollToTop();
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow,noarchive" />

        <meta charSet="utf-8" />
        <title>
          Kitchen Countertop New Jersey | Premium Quality Quartz, Marble,
          Granite
        </title>
        <link
          rel="canonical"
          href="https://www.astrasurfaces.com/countertop-store-in-linden-new-jersey"
        />
        <meta
          name="description"
          content="Astra Surfaces is a leading New Jersey Countertop provider for residential and commercial projects. We offer the best countertops in NJ, including- granite, marble, dolomite, porcelain and quartz."
        />
      </Helmet>
      <>
        {screenLoading ? (
          <Loader />
        ) : (
          <>
            <div id="countertop-london">
              <section className="hero-section2">
                <div className="bg-image">
                  <div className="text-wrapper">
                    <h1 className="fw-bold">
                      Bringing New Jersey homes <br /> to life with Porcelain
                      Countertops
                    </h1>
                  </div>
                </div>
              </section>

              <section className="about-section gap">
                <div className="container" data-aos="fade">
                  <h2 className="text-center">
                    Countertops For Kitchen And Bathroom in New Jersey- Astra
                    Surfaces
                  </h2>
                  <p className="p_tag">
                    Reliable, efficient, and honest, Astra Surfaces is a
                    reputable kitchen countertop distributor in New Jersey. As a
                    family-owned countertop showroom, we specialize in stones
                    like marble, granite, quartzite, porcelain, and quartz
                    countertops. Our specialties include kitchen countertops,
                    vanity tops, bar tops, fireplaces, window sills, outdoor
                    kitchens, staircases, and more. Our low-maintenance
                    countertops withstand the busiest homes and commercial
                    spaces without compromising style or functionality.
                  </p>

                  <p className="p_tag">
                    What are you waiting for? Astra Surfaces is your destination
                    for natural stone countertops in New Jersey. Whether it's a
                    new home, office, or renovation, our team is dedicated to
                    helping you choose the right countertop for your space.
                    Explore our diverse selection and be inspired.
                  </p>
                </div>
              </section>

              <section className="product-section gap">
                <div className="container">
                  {/* <h2 className="text-center">PRODUCTS</h2> */}

                  <div className="row mt-md-5 mt-3 gx-3 justify-content-center">
                    {productsCategory &&
                      productsCategory.map((category) => {
                        let overlayText = "";
                        if (category.category === "Variant Quartz") {
                          overlayText =
                            "Explore our vast selection of curated quartz designs to meet anyone’s needs";
                        } else if (category.category === "Variant Porcelain™") {
                          overlayText =
                            "Explore our meticulously crafted porcelain designs that are universally stunning";
                        } else if (category.category === "Natural Stone") {
                          overlayText =
                            "Explore our quartzite, granite, marble, dolomite, soapstone, and sodalite slabs";
                        }
                        return (
                          <div
                            className="col-lg-3 col-md-4  mb-md-0 mb-3"
                            data-aos="fade-up"
                          >
                            <h3>
                              {category.category}
                              {category.category === "Variant Quartz" && (
                                <sup
                                  className="registeredTrademark"
                                  style={{ left: "0" }}
                                >
                                  ®
                                </sup>
                              )}
                            </h3>
                            <Link
                              to={`/products/${category.category.replace(
                                /\s+/g,
                                "-"
                              )}`}
                              onClick={() => handleClick(category)}
                            >
                              <div
                                className="image-wrapper"
                                data-fancybox="gallery"
                                // data-src={category.image}
                              >
                                <img
                                  src={category.image}
                                  alt={category.category}
                                  className="image-1"
                                />
                                <div className="overlay">
                                  <p>{overlayText}</p>
                                </div>
                              </div>
                            </Link>
                            <Link
                              to={`/products/${category.category.replace(
                                /\s+/g,
                                "-"
                              )}`}
                              onClick={() => handleClick(category)}
                            >
                              <button className="product-btn">
                                View Product
                              </button>
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </section>

              <section className="cta-section mt-5">
                <div className="bg-image2">
                  <div className="row h-100 d-flex justify-content-center align-items-center">
                    <div className="col-10 d-flex justify-content-center text-center">
                      <div className="text-wrapper" data-aos="zoom-in">
                        <h3>Need help picking the right product?</h3>
                        <p>
                          Get in touch with our representative or get a call
                          back!
                        </p>
                        <div>
                          <a href="tel:+1 908-988-9902">+1 908-988-9902</a>
                          <span>or</span>
                          <Link to={"/contact-us"}>
                            <button>Request A Callback</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <ValueSection />

              <AimSection />
              <section className="cta-section gap">
                <div className="bg-image2">
                  <div className="row h-100 d-flex justify-content-center align-items-center">
                    <div className="col-10 d-flex justify-content-center text-center">
                      <div className="text-wrapper" data-aos="zoom-in">
                        <h3>Need help picking the right product?</h3>
                        <p>
                          Get in touch with our representative or get a call
                          back!
                        </p>
                        <div>
                          <a href="">+1 908-988-9902</a>
                          <span>or</span>
                          <Link to={"/contact-us"}>
                            <button>Request A Callback</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section-9">
                <div className="container">
                  <div className="row gx-0">
                    <div className="col-md-4">
                      <div className="col-lg-12">
                        <div className="wrapper">
                          <img
                            src="assests/countertoplondon/image04.jpg"
                            alt=""
                            style={{ height: "600px", width: "100%" }}
                          />
                          <div className="text-wrapper">
                            <p>Text Heading</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="wrapper">
                          <img
                            src="assests/countertoplondon/image05.jpg"
                            alt=""
                            style={{ height: "300px", width: "100%" }}
                          />
                          <div className="text-wrapper">
                            <p>Text Heading</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mt-md-0 mt-3">
                      <div className="col-lg-12">
                        <div className="wrapper">
                          <img
                            src="assests/countertoplondon/image06.jpg"
                            alt=""
                            style={{ height: "600px", width: "100%" }}
                          />
                          <div className="text-wrapper">
                            <p>Text Heading</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="wrapper">
                          <img
                            src="assests/countertoplondon/image07.jpg"
                            alt=""
                            style={{ height: "300px", width: "100%" }}
                          />
                          <div className="text-wrapper">
                            <p>Text Heading</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mt-md-0 mt-3">
                      <div className="col-lg-12">
                        <div className="wrapper">
                          <img
                            src="assests/countertoplondon/image08.jpg"
                            alt=""
                            style={{ height: "300px", width: "100%" }}
                          />
                          <div className="text-wrapper">
                            <p>Text Heading</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="wrapper">
                          <img
                            src="assests/countertoplondon/image09.jpg"
                            alt=""
                            style={{ height: "600px", width: "100%" }}
                          />
                          <div className="text-wrapper">
                            <p>Text Heading</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <CatalogueSection />

              <BlogSection />
            </div>
          </>
        )}
      </>
    </>
  );
};

export default CountertopLondon;
